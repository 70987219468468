import "./styles/App.css"
import "./styles/App.less"

import Home from "./modules/home"
import { ConfigProvider } from 'antd'
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom"
import themeAntd from './styles/themeAntd'
import LoginERP from "./modules/erp/login"
import ERP from "./modules/erp"
import TourDetail from "./modules/tourDetail"
import AboutUs from "./modules/aboutUs"
import ContactUs from "./modules/contactUs"

const App = ({ authorized, firebase, user }) => (
    <ConfigProvider theme={{ token: themeAntd }}>
        <Router>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/tour-detail" element={<TourDetail />} />
                <Route exact path="/about-us" element={<AboutUs />} />
                <Route exact path="/contact-us" element={<ContactUs />} />

                <Route exact path="/login-bella-phuket-on-tours-back-office" element={<LoginERP authorized={authorized} firebase={firebase} user={user} />} />
                <Route exact path="/bella-phuket-on-tours-back-office" element={<ERP authorized={authorized} firebase={firebase} user={user} />} />
                <Route path="/:id">Page not found!</Route>
            </Routes>
        </Router>
    </ConfigProvider>
)

export default App
