/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-duplicate-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useEffect, useMemo } from 'react'
import {
    Button,
    Layout,
    Menu
} from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { useNavigate, useLocation } from 'react-router-dom'

import TourManage from './manage/tour'
import { getErpSignOutFetch } from './API'
import { Notification } from '../../common/components/notification'
import LoadingPage from '../../common/components/loading/page'

const { Sider } = Layout
// const { SubMenu } = Menu

export default function ERP(props) {
    const navigate = useNavigate()
    const location = useLocation()

    const [loading, setLoading] = useState(true)

    const [selectedMenuItem, setSelectedMenuItem] = useState("manage-tour")

    const componentsSwitch = (type) => {
        switch (type) {
            // case "manage-admin":
            //     return <AdminManage />

            case "manage-tour":
                return <TourManage username={location.state.username} />

            default:
                return null
        }
    }

    const detectSignin = () => {
        setLoading(true)

        const accessToken = sessionStorage.getItem("accessToken")
        // console.log("X --- : ", accessToken)
        if (!accessToken) {
            navigate("/login-is-asset-home-back-office")
        } else {
            setLoading(false)
        }
    }

    useMemo(() => {
        detectSignin()
    }, [])

    useEffect(() => {
        setInterval(async () => {
            detectSignin()
        }, 1000)
    }, [])



    return (
        <>
            {!loading ?
                <div style={{ backgroundColor: '#ECECEC' }}>
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <PageHeader
                            title={
                                <>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ paddingLeft: 10, display: "grid", alignItems: "center", color: "white" }} >
                                            <label style={{ fontSize: 20, fontWeight: 500 }}>
                                                Bella Phuket On Tour Service (Administrator)
                                            </label>
                                            <label style={{ color: "white", fontSize: 16, fontWeight: "initial" }}>
                                                <label style={{}}>ผู้ใช้งาน : {location.state ? location.state.username : "-"}</label>
                                            </label>
                                        </div>
                                    </div>
                                </>
                            }
                            extra={[
                                <Button
                                    type="text"
                                    onClick={async () => {
                                        const accessToken = sessionStorage.getItem("accessToken")
                                        const result = await getErpSignOutFetch(accessToken)
                                        if (result.isSuccess) {
                                            navigate("/bella-phuket-on-tours-back-office", { state: null })
                                            navigate("/login-bella-phuket-on-tours-back-office")
                                            sessionStorage.removeItem('accessToken')
                                        } else {
                                            Notification('error', 'ไม่สามารถออกจากระบบได้ กรุณาลองใหม่อีกครั้ง')
                                        }
                                    }}
                                    style={{ marginTop: 16, color: "white" }}
                                >ออกจากระบบ</Button>
                            ]}
                            style={{ backgroundColor: 'black' }}
                        >
                        </PageHeader>

                        <Layout>
                            <Sider
                                width={200}
                                className="site-layout-background"
                            >
                                <Menu
                                    defaultSelectedKeys={['1']}
                                    defaultOpenKeys={['manage-tour']}
                                    mode="inline"
                                    theme="dark"
                                    selectedKeys={selectedMenuItem}
                                    onClick={(e) => {
                                        setSelectedMenuItem(e.key)
                                    }}
                                    style={{ height: '100%' }}
                                >

                                    <Menu.Item key="manage-tour">
                                        <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการทัวร์</label>
                                    </Menu.Item>
                                </Menu>
                            </Sider>

                            <article style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                                <div style={{ padding: 20 }}>
                                    {componentsSwitch(selectedMenuItem)}
                                </div>
                            </article>
                        </Layout>
                    </div>
                </div>
                :
                <LoadingPage />
            }
        </>
    )
}
