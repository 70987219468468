/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Button, Col, Image, Row } from 'antd'
import { useEffect, useState } from 'react'
import Header from '../../common/components/header'
import { useDimensions } from '../../common/mamager/ScreenManager'
import parse from 'html-react-parser'
import { useLocation, useNavigate } from 'react-router'
import Footer from '../../common/components/footer'
import Gallery from '../../common/components/gallery'
import { FaPhoneAlt } from "react-icons/fa"
import { Icon } from '@iconify/react'
import { getActivityImagesPageFetch, getTourDetailPageFetch } from './API'
import YouTube, { YouTubeProps } from 'react-youtube'

export default function TourDetail() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const tourId = params.get("tourId")

    const [loading, setLoading] = useState(false)

    const { width } = useDimensions()
    const [tourDetail, setTourDetail] = useState()
    const [tourGallery, setTourGallery] = useState([])

    const navigate = useNavigate()

    const getPartnerAll = async () => {
        setLoading(true)

        let param = {
            id: Number(tourId)
        }

        let resultTourDetail = await getTourDetailPageFetch(param, null, null)
        console.log("getTourDetailPageFetch : ", resultTourDetail[0])
        if (resultTourDetail?.length > 0) {
            setTourDetail(resultTourDetail[0])
        }

        let resultActivityImages = await getActivityImagesPageFetch(param, null, null)
        // console.log("getActivityImagesPageFetch : ", resultActivityImages)
        if (resultActivityImages?.length > 0) {
            setTourGallery(resultActivityImages)
        }

        setLoading(false)
    }

    const onPlayerReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo()
    }

    const opts = {
        // height: '390',
        width: '100%',
        playerVars: {
            autoplay: 1
        }
    }

    const getBaseApi = async () => {
        await getPartnerAll()
    }

    useEffect(() => {
        getBaseApi()
    }, [tourId])

    return (
        <>
            <Header />

            <div
                style={{
                    backgroundColor: "#EEEEEE",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        paddingTop: 70,
                        // paddingLeft: (width > 1200 ? 0 : 24),
                        // paddingRight: (width > 1200 ? 0 : 24),
                        maxWidth: 1200,
                        width: "100%"
                    }}
                >
                    {!loading ?
                        <>
                            <Row
                                gutter={[0, 24]}
                                style={{
                                    backgroundColor: "white",
                                    paddingTop: 24,
                                    // paddingBottom: 84,
                                    paddingLeft: 24,
                                    paddingRight: 24
                                }}
                            >
                                <Col span={24}>
                                    <label style={{ fontWeight: 600, fontSize: 20 }}>{tourDetail?.title ?? "-"}</label>
                                </Col>

                                <Col span={24}>
                                    <div style={{ paddingBottom: 0 }}>
                                        <img
                                            src={`${tourDetail?.imageThumbnail}`}
                                            style={{ objectFit: "cover", width: "100%", height: 400, borderRadius: 8 }}
                                        />
                                    </div>
                                </Col>

                                <Col span={24}>
                                    <label style={{ fontSize: 20 }}>{tourDetail?.subTitle ?? "-"}</label>
                                </Col>

                                <Col span={24}>
                                    {tourDetail?.tourImages?.map((val, index) => {
                                        return (
                                            <div key={index} style={{ paddingBottom: 12 }}>
                                                <Image
                                                    src={`${val?.image}`}
                                                    style={{ objectFit: "cover", width: "100%", borderRadius: 8 }}
                                                />
                                            </div>
                                        )
                                    })}

                                </Col>
                            </Row>

                            <div style={{ display: "grid", justifyContent: "center", alignItems: "center", paddingTop: 32, paddingBottom: 32 }}>
                                <label style={{ fontWeight: 600, paddingBottom: 12 }}>Contact us to book this tour.</label>

                                <div>
                                    <Button
                                        type="primary"
                                        size="large"
                                        style={{ width: 200 }}
                                        onClick={() => navigate("/contact-us")}
                                    >
                                        CONTACT US
                                    </Button>
                                </div>
                            </div>

                            {tourGallery?.length > 0 ?
                                <div style={{}}>
                                    <Gallery
                                        typeId={1}
                                        loading={loading}
                                        data={tourGallery}
                                        typeName={"Image activity"}
                                        isOpenPaddingLeft={false}
                                        isOpenPaddingRight={false}
                                        backgroundColor={"white"}
                                        isOpenBaseApi={false}
                                    />
                                </div>
                                : []
                            }

                            {/* <div style={{}}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "#EEEEEE"
                                    }}
                                >
                                    <div
                                        style={{
                                            paddingTop: 68,
                                            paddingBottom: 68,
                                            paddingLeft: 12,
                                            paddingRight: 12,
                                            maxWidth: 1200,
                                            width: 1200
                                        }}
                                    >
                                        <Row gutter={[0, 24]}>
                                            <Col span={24} style={{ textAlign: "center" }}>
                                                <label style={{ fontSize: 26 }}>Video activity</label>
                                            </Col>
                                            <Col span={24}>
                                                <YouTube
                                                    videoId="m_pCh6p8_wg" opts={opts} onReady={onPlayerReady} />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div> */}

                        </>
                        : []
                    }
                </div>
            </div>

            <Footer />
        </>
    )
}
