/* eslint-disable jsx-a11y/alt-text */
import { serverUrl } from "../../../constants"
import "./css/index.css"
import React from 'react'
import { Button, Col, Image, Row } from 'antd'
import { Icon } from '@iconify/react'
import { useNavigate } from "react-router"

export default function Card(props) {

    const navigate = useNavigate()

    // console.log("props : ", props)

    return (
        <div
            style={{
                // paddingLeft: 12,
                // paddingRight: (props.index % 10) ? 0 : 12,
                // paddingTop: 12,
                // paddingBottom: 12,
                width: props.width,
            }}
        >
            <div className="blog-card">
                {props?.isOnlyimage ?
                    <div
                        style={{
                            borderRadius: 8,
                            width: "100%",
                            height: props?.height
                        }}
                        onClick={() => {
                            navigate("/tour-detail?tourId=" + props.id)
                            window.scrollTo(0, 0)
                        }}
                    >
                        {props?.isRent || props?.isSale ?
                            <div class="top-left">
                                <div style={{ backgroundColor: "#9D00FF", borderRadius: "8px 0px 0px 0px", paddingTop: 4, paddingBottom: 4, paddingLeft: 8, paddingRight: 8, width: 50, display: "flex", justifyContent: "center" }}>
                                    <label style={{ color: "white" }}>
                                        {props?.isRent ? "ให้เช่า" : ""}
                                        {props?.isSale ? "ขาย" : ""}
                                    </label>
                                </div>
                            </div>
                            : []
                        }

                        <div style={{ paddingBottom: 6 }}>
                            <img
                                // src={`${serverUrl}/${props?.image}`}
                                src={`${props?.image}`}
                                style={{ objectFit: "cover", width: "100%", height: 200, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
                            />
                        </div>

                        <div style={{ height: props?.heightBody }}>
                            <div
                                style={{
                                    paddingLeft: 12,
                                    paddingRight: 6,
                                    // paddingBottom: 10,
                                    fontWeight: 600
                                }}
                            >
                                <div className="blog-title">
                                    <label style={{ cursor: "pointer" }}>{props?.title}</label>
                                </div>
                            </div>

                            <div
                                style={{
                                    paddingLeft: 12,
                                    paddingRight: 6,
                                    paddingBottom: 10,
                                }}
                            >
                                <div>
                                    <label style={{ cursor: "pointer" }}>{props?.subtitle}</label>
                                </div>
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "space-between",
                                paddingLeft: 12,
                                paddingRight: 12,
                                paddingBottom: 12
                            }}
                        >
                            <div
                                style={{
                                    display: "grid",
                                    backgroundColor: "#C8E4FF",
                                    padding: 12,
                                    borderRadius: 8,
                                    width: "100%"
                                }}
                            >
                                <label style={{ fontSize: 17, fontWeight: 600 }}>Start price</label>
                                <label style={{ fontSize: 17, fontWeight: 600 }}>Adult {Intl.NumberFormat("en", { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(Number(props.adl))} ฿</label>
                                <label style={{ fontSize: 17, fontWeight: 600 }}>Child {Intl.NumberFormat("en", { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(Number(props.chd))} ฿</label>
                            </div>

                            <div style={{ paddingLeft: 12 }}>
                                <Button
                                    type="primary"
                                    style={{ float: 'right' }}
                                    onClick={() => {
                                        navigate("/tour-detail?tourId=" + props.id)
                                        window.scrollTo(0, 0)
                                    }}
                                >
                                    <label style={{ cursor: "pointer" }}>DETAIL</label>
                                </Button>
                            </div>

                        </div>
                    </div>
                    :
                    <>
                        <Image
                            fallback={props?.isOpenBaseApi ? `${serverUrl}/${props?.image}` : `${props?.image}`}
                            src="error"
                            style={{ objectFit: "cover", width: "100%", height: 300, borderRadius: 8, border: "3px solid #9D00FF" }}
                        />
                    </>
                }

            </div>
        </div >
    )
}
