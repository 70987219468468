/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    Row,
    Col
} from 'antd'
import { useState } from 'react'
import { useDimensions } from '../../common/mamager/ScreenManager'
import Header from '../../common/components/header'
import Footer from '../../common/components/footer'

import PhotoAlbum from "react-photo-album"
import Lightbox from "yet-another-react-lightbox"
import slides from './data/slides'

export default function AboutUs() {

    const { width } = useDimensions()

    const [index, setIndex] = useState(-1);

    return (
        <>
            <Header />

            <div
                style={{
                    width: '100% auto',
                    height: '100% auto',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        paddingTop: width > 1200 ? 150 : (width > 760 && width < 1200 ? 150 : 150),
                        paddingBottom: 82,
                        paddingLeft: (width > 1200 ? "12%" : 24),
                        paddingRight: (width > 1200 ? "12%" : 24),
                        maxWidth: 1200,
                        width: "100%"
                    }}
                >
                    <div style={{ paddingBottom: 18 }}>
                        <label style={{ fontWeight: 600, fontSize: 26, color: "#0085FF" }}>ABOUT US</label>
                    </div>

                    <Row>
                        <Col xs={24} md={6} xl={6}>
                            <img src={"./assets/images/contact/img-main.jpg"} style={{ width: "100%", borderRadius: 16 }} />
                        </Col>
                        <Col xs={24} md={18} xl={18} style={{ paddingLeft: (width > 769 ? 24 : 0) }}>
                            <div style={{ paddingBottom: 18 }}>
                                <label>A warm welcome to booking tours on the Bella Phuket On Tour website. My name is Bella from more than 10 years of experience working in booking tours and driving taxis and providing car/van/SUV allocation services to suit tour groups in the province Phuket, Krabi, Phang Nga We have selected and selected tour programs that are of high quality and suitable for a fair price for you to choose according to your needs and suit you. </label>
                            </div>

                            <div style={{ paddingBottom: 18 }}>
                                <label>You can be confident in booking a tour under our website. Throughout the past, we have strived to develop our trip arrangements and services even further and sincerely hope that we will be given the opportunity to talk and book various trips with us. </label>
                            </div>

                            <div style={{ paddingBottom: 18 }}>
                                <label>We have registered and received a tourism business operating license, TAT number. 34/5567 correctly. Because we know that service work requires attentive, friendly, straightforward, honest, and willing service. </label>
                            </div>

                            <div style={{ paddingBottom: 18 }}>
                                <label>This is the reason you must contact us. Thank you </label>
                            </div>

                            <div style={{ paddingBottom: 18 }}>
                                <label>TAT 34/5567</label>
                            </div>

                            {/* <div style={{ paddingBottom: 0 }}>
                                <label>Phone number +66931892902</label>
                            </div>

                            <div style={{ paddingBottom: 0 }}>
                                <label>Fb.Bella Taxi Tour Guide WhatsApp +66931892902 LINE</label>
                            </div> */}
                        </Col>
                    </Row>

                    <div style={{ paddingTop: 32 }}>
                        <PhotoAlbum
                            layout="rows"
                            photos={slides}
                            targetRowHeight={300}
                        />
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}
