/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */

import {
    Row,
    Col,
    Button,
    Modal
} from 'antd'
import { useDimensions } from '../../common/mamager/ScreenManager'
import Header from '../../common/components/header'
import Footer from '../../common/components/footer'
import { Icon } from '@iconify/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { getHomePageSlideBannersFetch } from '../../common/components/header/API'

export default function ContactUs() {

    const navigate = useNavigate()
    const { width } = useDimensions()

    const [tours, setTours] = useState([])

    const [loading, setLoading] = useState(false)

    const [isModalLineVisible, setIsModalLineVisible] = useState(false)
    const [isModalWhatsappVisible, setIsModalWhatsappVisible] = useState(false)

    const handleModalLine = () => {
        setIsModalLineVisible(false)
    }

    const handleModalWhatsapp = () => {
        setIsModalWhatsappVisible(false)
    }

    const getPropertiesBySearch = async (name) => {
        setLoading(true)

        let param = {
            // page: pageCurrentRef.current,
            // size: pageSize
        }

        let result = await getHomePageSlideBannersFetch(null, null, null)
        // console.log("getHomePageSlideBannersFetch : ", result)

        // console.log(" : ", result)
        // setTotal(result?.totalItems)
        setTours(result)
        // searchNameRef.current = name

        setLoading(false)
    }

    const getBaseApi = async () => {
        await getPropertiesBySearch(null)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            <Header />

            <div
                style={{
                    width: '100% auto',
                    height: '100% auto',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        paddingTop: width > 1200 ? 150 : (width > 760 && width < 1200 ? 150 : 150),
                        paddingBottom: 82,
                        paddingLeft: (width > 1200 ? "12%" : 24),
                        paddingRight: (width > 1200 ? "12%" : 24),
                        maxWidth: 1200,
                        width: "100%"
                    }}
                >
                    <div style={{ paddingBottom: 18 }}>
                        <label style={{ fontWeight: 600, fontSize: 26, color: "#0085FF" }}>CONTACT US</label>
                    </div>

                    <Row>
                        <Col xs={24} md={14} xl={14}>
                            <div style={{ paddingBottom: 18 }}>
                                <label style={{ fontSize: 24, fontWeight: 600 }}>Bella On Tour</label>
                            </div>

                            <div style={{ paddingBottom: 18, display: "flex", justifyItems: "flex-start" }}>
                                <div style={{ marginTop: 2, marginLeft: -5, paddingRight: 4 }}>
                                    <Icon icon="mdi:location" style={{ color: "#0085FF", width: 20, height: 20 }} />
                                </div>
                                <label>52/143 Villa Song Village, Village No. 2, Soi 6, Wichit Subdistrict, Mueang District, Phuket Province 83000</label>
                            </div>

                            <div style={{ display: "grid", alignItems: "flex-start", paddingLeft: (width > 767 ? 0 : 0) }}>
                                <div
                                    style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                                    onClick={() => {
                                        window.open("tel:+66931892902")
                                    }}
                                >
                                    <div style={{ height: 70, width: 70, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0085FF", borderRadius: "16px 0px 0px 16px" }}>
                                        <Icon icon="mingcute:phone-fill" style={{ fontSize: 40, color: "white" }} />
                                    </div>

                                    <div style={{ height: 70, minWidth: 180, backgroundColor: "#EEEEEE", borderRadius: "0px 16px 16px 0px" }}>
                                        <div style={{ padding: 12 }}>
                                            <label style={{ fontSize: 16, color: "black", cursor: "pointer" }}>โทร</label>
                                            <br />
                                            <label style={{ fontSize: 16, fontWeight: 600, color: "black", cursor: "pointer" }}>093-189-2902</label>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: "flex", alignItems: "center", paddingTop: 18 }}>
                                    <div style={{ paddingLeft: 0 }}>
                                        <Button
                                            type="primary"
                                            style={{ width: 38 }}
                                            size="middle"
                                            onClick={(e) => {
                                                window.open("https://www.facebook.com/people/Bella-Taxi-Tour-Guide/100064570396638")
                                            }}
                                        >
                                            <div style={{ marginTop: 3, marginLeft: -7 }} >
                                                <Icon icon="cib:facebook-f" style={{ color: "white", width: 20, height: 20 }} />
                                            </div>
                                        </Button>
                                    </div>

                                    <div style={{ paddingLeft: 10 }}>
                                        <Button
                                            type="primary"
                                            style={{ width: 38 }}
                                            size="middle"
                                            onClick={(e) => {
                                                setIsModalLineVisible(true)
                                            }}
                                        >
                                            <div style={{ marginTop: 3, marginLeft: -7 }} >
                                                <Icon icon="bi:line" style={{ color: "white", width: 20, height: 20 }} />
                                            </div>
                                        </Button>
                                    </div>

                                    <div style={{ paddingLeft: 10 }}>
                                        <Button
                                            type="primary"
                                            style={{ width: 38 }}
                                            size="middle"
                                            onClick={(e) => {
                                                setIsModalWhatsappVisible(true)
                                            }}
                                        >
                                            <div style={{ marginTop: 3, marginLeft: -7 }} >
                                                <Icon icon="icomoon-free:whatsapp" style={{ color: "white", width: 20, height: 20 }} />
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={10} xl={10}>
                            <div style={{ display: "grid", paddingLeft: (width > 767 ? 24 : 0), paddingTop: 12 }}>
                                <label style={{ fontSize: 24, fontWeight: 600, paddingBottom: 12 }}>Activity tours :</label>
                                {tours?.map((val, index) => {
                                    return (
                                        <label
                                            key={index}
                                            onClick={() => {
                                                navigate("/tour-detail?tourId=" + val.id)
                                                window.scrollTo(0, 0)
                                            }}
                                            className="tour-item"
                                        >{val.title}</label>
                                    )
                                })}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <Modal
                title="Line"
                visible={isModalLineVisible}
                onCancel={handleModalLine}
                // width={1000}
                footer={[]}
            >
                <img
                    style={{ objectFit: "cover", width: "100%", height: 500, borderRadius: 8 }}
                    src={`./assets/images/contact/line-qrcode.jpg`}
                />
            </Modal>

            <Modal
                title="WhatsApp"
                visible={isModalWhatsappVisible}
                onCancel={handleModalWhatsapp}
                // width={1000}
                footer={[]}
            >
                <img
                    style={{ objectFit: "cover", width: "100%", height: 500, borderRadius: 8 }}
                    src={`./assets/images/contact/whatsapp-qrcode.jpg`}
                />
            </Modal>
        </>
    )
}
