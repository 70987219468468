/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import styled from 'styled-components'

import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
	Drawer,
	Button,
	Menu
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from "../../mamager/ScreenManager"
import { getHomePageSlideBannersFetch } from "./API"

import "../header/css/index.css"

const { SubMenu } = Menu

export default function Header(props) {
	const navigate = useNavigate()

	const { width } = useDimensions()

	const [state, setState] = useState({
		current: 'mail',
		visible: false
	})

	const [popupOpen, setPopupOpen] = useState(false)

	const [tours, setTours] = useState([])

	const [loading, setLoading] = useState(false)

	const getPropertiesBySearch = async (name) => {
		setLoading(true)

		let param = {
			// page: pageCurrentRef.current,
			// size: pageSize
		}
		let result = await getHomePageSlideBannersFetch(null, null, null)
		// console.log("getHomePageSlideBannersFetch : ", result)
		// setTotal(result?.totalItems)
		setTours(result)
		// searchNameRef.current = name

		setLoading(false)
	}

	const openPopup = () => {
		setPopupOpen(true)
	}

	const closePopup = () => {
		setPopupOpen(false)
	}

	const showDrawer = () => {
		setState({
			visible: true
		})
	}

	const onClose = () => {
		setState({
			visible: false
		})
	}

	const genRightMenu = () => {
		return (
			<Menu mode="inline">
				<Menu.Item
					key="1"
					onClick={() => {
						openPath("home")
					}}
				>
					WELCOME
				</Menu.Item>

				<SubMenu key="2" title="ACTIVITY TOUR">
					{tours?.map((val, index) => {
						return (
							<Menu.Item
								key={"sub-" + index}
								onClick={() => {
									navigate("/tour-detail?tourId=" + val.id)
									window.scrollTo(0, 0)
								}}
							>
								{val.title}
							</Menu.Item>
						)
					})}
				</SubMenu>

				<Menu.Item
					key="3"
					onClick={() => {
						openPath("about_us")
					}}
				>
					ABOUT US
				</Menu.Item>

				<Menu.Item
					key="4"
					onClick={() => {
						openPath("contact_us")
					}}
				>
					CONTACT US
				</Menu.Item>
			</Menu>
		)
	}

	const openPath = (menu) => {
		if (menu === "home") {
			navigate("/")
		} else if (menu === "about_us") {
			navigate("/about-us")
		} else if (menu === "contact_us") {
			navigate("/contact-us")
		}
	}

	const getBaseApi = async () => {
		await getPropertiesBySearch(null)
	}

	useEffect(() => {
		getBaseApi()
	}, [])

	const genLeftMenu = () => {
		return (
			<header className="header">
				<nav className={"menu"}>
					<ul>
						<li onClick={() => navigate("/")}>
							<label className="menu-toggle">WELCOME</label>
						</li>

						<li
							onMouseEnter={openPopup}
							onMouseLeave={closePopup}
						>
							<label className="menu-toggle">ACTIVITY TOUR</label>

							{popupOpen && (
								<div className="popup">
									<ul>
										{tours?.map(val => {
											return (
												<li
													onClick={() => {
														navigate("/tour-detail?tourId=" + val.id)
														window.scrollTo(0, 0)
													}}
												>{val.title}</li>
											)
										})}
									</ul>
								</div>
							)}
						</li>

						<li onClick={() => navigate("/about-us")}>
							<label className="menu-toggle">ABOUT US</label>
						</li>

						<li onClick={() => navigate("/contact-us")}>
							<label className="menu-toggle">CONTACT US</label>
						</li>
					</ul>
				</nav>
			</header>
		)
	}



	return (
		<div
			style={{
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: width > 1200 ? "10%" : (width > 760 && width < 1200 ? 12 : 12),
				paddingRight: width > 1200 ? "10%" : (width > 760 && width < 1200 ? 12 : 12),
				position: 'fixed',
				zIndex: 2,
				width: width > 1200 ? "80%" : (width > 760 && width < 1200 ? "98%" : "98%"),
				backgroundColor: '#0085FF',
				boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.4)',
				height: 70,
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				// overflow: 'hidden',
			}}
		>
			<div style={{ display: "flex", alignItems: "center" }}>
				<div style={{ display: "flex", alignItems: "center", color: "white" }}>
					<img
						src={"./assets/images/logo/bella-on-tour-logo.png"}
						style={{ width: 55, height: 55, borderRadius: 60, cursor: "pointer" }}
						onClick={() => {
							navigate("/")
							window.scrollTo(0, 0)
						}}
					/>

					<label style={{ fontSize: (width > 600 ? 26 : 20) , paddingLeft: 24 }}>Bella Phuket on tours</label>
				</div>
			</div>

			{width > 1012 ?
				genLeftMenu()
				:
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
					<Drawer
						title={<label style={{ fontSize: 16, fontWeight: 100 }}>Bella Phuket on tours</label>} placement="right"
						closable={false}
						onClose={onClose}
						visible={state.visible}
						bodyStyle={{ padding: '0px' }}
						size="default"
					>
						{genRightMenu()}
					</Drawer>

					<Button
						type="primary"
						onClick={showDrawer}
						style={{
							height: 70,
							width: 85,
							borderRadius: 0
						}}
					>
						<Icon
							icon="charm:menu-hamburger"
							style={{
								fontSize: '2.5em',
								marginTop: 4,
								marginLeft: -8,
								color: "white"
							}}
						/>
					</Button>
				</div>
			}
		</div>
	)
}