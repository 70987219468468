
export const HTTP_BASKET_ORDER_FETCHING = "HTTP_BASKET_ORDER_FETCHING"
export const HTTP_BASKET_ORDER_SUCCESS = "HTTP_BASKET_ORDER_SUCCESS"
export const HTTP_BASKET_ORDER_FAILED = "HTTP_BASKET_ORDER_FAILED"

///////////////////////// Localization Begin /////////////////////////
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK"
export const NETWORK_CONNECTION_MESSAGE = "NETWORK_CONNECTION_MESSAGE"

export const serverUrl = "https://bella-phuket-on-tours.witte-bangkok.com"
// export const serverUrl = "http://localhost:3030"

export const apiServerUrl = serverUrl // + "" "/api/v1"

export const imgDefaultUrl = `./assets/images/default/df-img.png`
export const videoDefaultUrl = `./assets/images/default/df-vdo.png`
export const imgDefaltCardCourse = `./assets/images/default/df-card-course-img.png`
export const img404notfound = `./assets/images/notfound/404notfound.jpg`

export const paginate = 10000 // limit 10 k
export const sortASC = "ASC"
export const sortDESC = "DESC"

export const YES = "YES"
export const NO = "NO"
export const OK = "ok"
export const NOK = "nok"

export const server = {
    // ------------------------ TOURS ----------------------------------------------------------
    GET_TOURS_URL: `/api/tours`,
    GET_TOURS_BY_ID_URL: `/api/tours`,
    GET_HOMEPAGE_SLIDE_BANNERS_URL: `/api/homePage/slideBanners`,
    GET_HOMEPAGE_TOURS_URL: `/api/homePage/tours`,
    GET_TOUR_DETAIL_PAGE_URL: `/api/tourDetailPage`,
    GET_ACTIVITY_IMAGES_PAGE_URL: `/api/tourDetailPage/activityImages`,

    INSERT_TOURS_URL: `/api/tours`,

    UPDATE_TOURS_URL: `/api/tours`,

    DELETE_TOURS_BY_ID_URL: `/api/tours`,

    // ------------------------ TOUR IMAGE -----------------------------------------------------
    INSERT_TOUR_IMAGES_URL: `/api/tour-images`,

    DELETE_TOUR_IMAGES_BY_ID_URL: `/api/tour-images`,

    // ------------------------ TOUR ACTIVITY IMAGE --------------------------------------------
    INSERT_TOUR_ACTIVITY_IMAGES_URL: `/api/tour-activity-images`,

    DELETE_TOUR_ACTIVITY_IMAGES_BY_ID_URL: `/api/tour-activity-images`,

    // ------------------------ UPLOADS --------------------------------------------------------
    UPLOAD_FILE: `/api/upload`,
    UPLOAD_FILES: `/api/upload/multiple`,

    // ------------------------ ERP ------------------------------------------------------------
    GET_ERP_SIGNIN_ID_URL: `/api/auth/signIn`,
    GET_ERP_SIGNUP_ID_URL: `/api/auth/signup`,
    GET_ERP_SIGNOUT_ID_URL: `/api/auth/signout`,
    GET_ERP_LIST_URL: ``,
}
