/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */

import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation, Mousewheel, Keyboard, Autoplay } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import "./css/index.css"

import { useEffect, useState } from "react"
import { useDimensions } from "../../mamager/ScreenManager"
import { Button, Image } from "antd"
import { useNavigate } from "react-router"

export default function Carouse(props) {

    const { width } = useDimensions()

    const navigate = useNavigate()

    return (
        <div>
            {props.data?.length > 0 ?
                <Swiper
                    modules={[Pagination, Navigation, Mousewheel, Keyboard, Autoplay]}
                    slidesPerView={1}
                    centeredSlides={true}
                    spaceBetween={12}
                    initialSlide={0}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false
                    }}
                    keyboard={{ enabled: true }}
                    pagination={{
                        clickable: true
                    }}
                    navigation={true}
                    loop={true}
                    className="mySwiper"
                >
                    {props.data.map((val, index) =>
                        <SwiperSlide key={index}>
                            <img
                                src={`${val?.imageThumbnail}`}
                                alt={val?.title ?? ""}
                                style={{
                                    height: 400,
                                    width: "100%",
                                    filter: "brightness(80%)"
                                }}
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    top: width > 800 ? "33%" : "35%",
                                    bottom: "35%",
                                    display: "grid",
                                    justifyContent: "center",
                                    width: "100%",
                                    textAlign: "center"
                                }}
                            >
                                <label
                                    style={{
                                        fontWeight: 500,
                                        fontSize: 32,
                                        color: "white",
                                        paddingLeft: 12, 
                                        paddingRight: 12
                                    }}
                                >
                                    {val?.title ?? ""}
                                </label>

                                <label
                                    style={{
                                        fontWeight: 500,
                                        fontSize: 24,
                                        color: "white",
                                        paddingLeft: width > 1200 ? 60 : (width > 760 && width < 1200 ? 40 : 46),
                                        paddingRight: width > 1200 ? 60 : (width > 760 && width < 1200 ? 40 : 46)
                                    }}
                                >
                                    {val?.subTitle ?? ""}
                                </label>

                                <center>
                                    <div style={{ paddingTop: 12 }}>
                                        <Button
                                            type="primary"
                                            size="large"
                                            style={{ width: 200 }}
                                            onClick={() => navigate("/tour-detail?tourId=" + val.id)}
                                        >
                                            SEE OUR DAY TOUR
                                        </Button>
                                    </div>
                                </center>
                            </div>
                            <meta itemprop="name" content={val?.title ?? ""} />
                            <meta itemprop="keywords" content={val?.title ?? ""} />
                        </SwiperSlide>
                    )}
                </Swiper>
                : []
            }
        </div>
    )
}