const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

const unsplashLink = (id, width, height) =>
    `./assets/images/contact/activity/${id}`;

const unsplashPhotos = [
    {
        id: "act1.jpg",
        width: 1044,
        height: 1566
    },
    {
        id: "act2.jpg",
        width: 1038,
        height: 1574
    },
    {
        id: "act3.jpg",
        width: 768,
        height: 1024
    },
    {
        id: "act4.jpg",
        width: 1108,
        height: 1477
    },
    {
        id: "act5.jpg",
        width: 1477,
        height: 1108
    },
    {
        id: "act6.jpg",
        width: 1477,
        height: 1108
    },
    {
        id: "act7.jpg",
        width: 1118,
        height: 525
    },
    {
        id: "act8.jpg",
        width: 900,
        height: 1600
    },
    {
        id: "act9.jpg",
        width: 1122,
        height: 1458
    },
    {
        id: "act10.jpg",
        width: 1114,
        height: 1469
    },
    {
        id: "act11.jpg",
        width: 1122,
        height: 1458
    },
    {
        id: "act12.jpg",
        width: 1706,
        height: 960
    },
]

export const slides = unsplashPhotos.map((photo) => {
    const width = photo.width * 4;
    const height = photo.height * 4;
    return {
        src: unsplashLink(photo.id, width, height),
        width,
        height,
        srcSet: breakpoints.map((breakpoint) => {
            const breakpointHeight = Math.round((height / width) * breakpoint);
            return {
                src: unsplashLink(photo.id, breakpoint, breakpointHeight),
                width: breakpoint,
                height: breakpointHeight,
            };
        }),
    };
});

export const advancedSlides = [
    { ...slides[0], title: "Puppy in sunglasses", description: "Mollie Sivaram" },
    {
        ...slides[1],
        title: "Miami Beach",
        description:
            "Clark Van Der Beken\n\nSouth Beach, Miami Beach, Florida, United States",
    },
    {
        ...slides[2],
        title: "Flamingo",
        description: "Vicko Mozara\n\nVeliki zali, Dubravica, Croatia",
    },
    {
        type: "video",
        title: "Big Buck Bunny",
        description:
            "The Peach Open Movie Project\n\nBlender Institute, Netherlands",
        width: 1280,
        height: 720,
        poster:
            "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg",
        sources: [
            {
                src: "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
                type: "video/mp4",
            },
        ],
    },
    {
        ...slides[3],
        title: "Starfish on a sand beach",
        description: "Pedro Lastra\n\nKey West, Florida, United States",
    },
    {
        ...slides[6],
        title:
            "The last night of a two week stay on the North Shore of Oahu, Hawaii",
        description: "Sean Oulashin\n\nNorth Shore, Waialua, Hawaii, United States",
    },
    {
        ...slides[7],
        title: "Sunset on Kauai",
        description: "Cristofer Maximilian\n\nKauai, Hawaii, United States",
    },
    {
        ...slides[9],
        title: "RayBan sunglasses",
        description: "Ethan Robertson\n\nSanta Monica, California, United States",
    },
    {
        ...slides[11],
        title: "Find the time",
        description: "Alex Perez\n\nNaples, Florida, United States",
    },
];

export default slides;
