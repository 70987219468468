/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    Popconfirm,
    Select,
    Upload,
    InputNumber,
    Radio,
    // DatePicker 
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { apiServerUrl, server } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { deleteToursByIdFetch, getToursFetch, insertToursFetch, updateToursFetch } from './API/tourApi'
import { deleteTourImageByIdFetch, insertTourImageFetch } from './API/tourImageApi'
import { deleteTourActivityImageByIdFetch, insertTourActivityImageFetch } from './API/tourActivityImageApi'

const { TextArea } = Input;
const { Option } = Select
// const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No.',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'ชื่อทัวร์',
        dataIndex: 'title',
        width: "20%",
    },
    {
        title: 'รูปภาพหน้าปก',
        dataIndex: 'imageThumbnail',
        width: "10%",
        render: (text, record) => {
            return (
                <>
                    <img src={record.imageThumbnail} alt={record.title} style={{ width: 100 }} />,
                </>
            )
        }
    },
    {
        title: 'สถานะ',
        width: "10%",
        render: (text, record) => {
            return (
                <>
                    {record.isActive ?
                        <label>แสดงข้อมูล</label>
                        :
                        <label>ปิดแสดงข้อมูล</label>
                    }
                </>
            )
        }
    },
    {
        title: 'สถานะไฮไลท์',
        width: "10%",
        render: (text, record) => {
            return (
                <>
                    {record.isHighlight ?
                        <label>แสดงข้อมูล</label>
                        :
                        <label>ปิดแสดงข้อมูล</label>
                    }
                </>
            )
        }
    },
    {
        title: 'ลำดับไฮไลท์',
        dataIndex: 'highlightSort',
        width: "10%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'เเก้ไขล่าสุด',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "10%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function VehicleManage(props) {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingTourGallery, setLoadingTourGallery] = useState(false)
    const [loadingTourActivityGallery, setLoadingTourActivityGallery] = useState(false)

    const [formTour] = Form.useForm()
    // const [formPropertyGallery] = Form.useForm()
    const [formSearch] = Form.useForm()

    const accessToken = sessionStorage.getItem("accessToken")

    const [tourId, setTourId] = useState()

    const [imageThumbnailURL, setImageThumbnailURL] = useState({
        loading: false,
        imageUrl: null
    })

    const [tourGallery, setTourGallery] = useState([])

    const isActiveTourGalleryRef = useRef(false)

    const [imageTourGalleryURL, setImageTourGalleryURL] = useState({
        loading: false,
        imageUrl: null
    })

    const [tourActivityGallery, setTourActivityGallery] = useState([])

    const isActiveTourActivityGalleryRef = useRef(false)

    const [imageTourActivityGalleryURL, setImageTourActivityGalleryURL] = useState({
        loading: false,
        imageUrl: null
    })

    const pageCurrentRef = useRef(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const searchNameRef = useRef("")

    const [modalTour, setModalTour] = useState({
        isShow: false,
        title: null
    })

    const optionTourImage = {
        name: 'file',
        action: `${apiServerUrl}${server.UPLOAD_FILE}?type=tour`,
        data: {
            name: "admin"
        },
        headers: {
            'x-access-token': accessToken
        },
        onChange(info) {

            if (info.file.status !== 'uploading') {
                let result = info.file.response
                if (result?.isSuccess) {
                    // console.log("A1 --- : ", result.formData.fileUrl)
                    setImageThumbnailURL({
                        imageUrl: result.formData.fileUrl,
                        loading: false
                    })
                }
            } else {
                setImageThumbnailURL({
                    imageUrl: imageThumbnailURL.imageUrl,
                    loading: true
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionTourGalleryImage = {
        name: 'file',
        action: `${apiServerUrl}${server.UPLOAD_FILE}?type=tour`,
        data: {
            name: "admin"
        },
        headers: {
            'x-access-token': accessToken
        },
        async onChange(info) {

            let result = info.file.response
            if (info.file.status !== 'uploading') {
                if (result?.isSuccess) {
                    // console.log("A2 --- : ", result.formData.fileUrl)
                    setImageTourGalleryURL({
                        imageUrl: result.formData.fileUrl,
                        loading: false
                    })
                }
            } else {
                setImageTourGalleryURL({
                    imageUrl: imageTourGalleryURL.imageUrl,
                    loading: true
                })
            }

            if (info.file.status === 'done') {
                setLoadingTourGallery(true)

                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")

                let body = {
                    tourId,
                    image: result.formData.fileUrl
                }
                const resultTourImage = await insertTourImageFetch(null, body, accessToken)
                isActiveTourGalleryRef.current = false

                let obj = {
                    tourId,
                    image: result.formData.fileUrl,
                    id: resultTourImage?.id
                }
                let tmpTourGallery = tourGallery
                tmpTourGallery?.push(obj)
                // console.log("tmpTourGallery : ", tmpTourGallery)
                setTourGallery(tmpTourGallery)

                setLoadingTourGallery(false)
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionTourActivityGalleryImage = {
        name: 'file',
        action: `${apiServerUrl}${server.UPLOAD_FILE}?type=tour`,
        data: {
            name: "admin"
        },
        headers: {
            'x-access-token': accessToken
        },
        async onChange(info) {

            let result = info.file.response
            if (info.file.status !== 'uploading') {
                if (result?.isSuccess) {
                    // console.log("A2 --- : ", result.formData.fileUrl)
                    setImageTourActivityGalleryURL({
                        imageUrl: result.formData.fileUrl,
                        loading: false
                    })
                }
            } else {
                setImageTourActivityGalleryURL({
                    imageUrl: imageTourActivityGalleryURL.imageUrl,
                    loading: true
                })
            }

            if (info.file.status === 'done') {
                setLoadingTourActivityGallery(true)

                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")

                let body = {
                    tourId,
                    image: result.formData.fileUrl
                }
                const resultTourActivityImage = await insertTourActivityImageFetch(null, body, accessToken)
                isActiveTourActivityGalleryRef.current = false

                let obj = {
                    tourId,
                    image: result.formData.fileUrl,
                    id: resultTourActivityImage?.id
                }
                let tmpTourActivityGallery = tourActivityGallery
                tmpTourActivityGallery?.push(obj)
                // console.log("tmpTourActivityGallery : ", tmpTourActivityGallery)
                setTourActivityGallery(tmpTourActivityGallery)

                setLoadingTourActivityGallery(false)
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const getToursAll = async (title) => {
        setLoading(true)

        let param = {
            title,
            isActive: "",
            page: pageCurrentRef.current,
            size: pageSize
        }
        const result = await getToursFetch(param, null, accessToken)
        // console.log("getToursFetch : ", result)
        setTotal(result?.totalItems)
        let tempList = []
        result?.results?.map((val, index) => {
            tempList.push({
                index: (pageCurrentRef.current * pageSize - 10) + (index + 1),
                imageThumbnail: val.imageThumbnail, // รูปภาพหน้าปก
                title: val.title, // ชื่อ
                subTitle: val.subTitle, // ชื่อ
                isActive: val.isActive, // สถานะการใช้งาน
                isHighlight: val.isHighlight, // สถานะการใช้งานไฮไลท์
                highlightSort: val.highlightSort, // แสดงลำดับไฮไลท์
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={async () => {
                                formTour.setFieldsValue({
                                    id: val.id, // 
                                    tourId: val.id, // 
                                    title: val.title, // 
                                    subTitle: val.subTitle, // 
                                    keyword: val.keyword, // 
                                    startPriceAdult: val.startPriceAdult, // 
                                    startPriceChild: val.startPriceChild, // 
                                    isActive: val.isActive, // 
                                    isHighlight: val.isHighlight, // 
                                    highlightSort: val.highlightSort, // 
                                    videoTourDetail: val.videoTourDetail, // 
                                })

                                setImageThumbnailURL({
                                    loading: false,
                                    imageUrl: val.imageThumbnail	
                                })

                                setTourId(val.id)
                                // console.log("tourId : ", val.id)

                                setTourGallery(val.tourImages)
                                setTourActivityGallery(val.tourActivityImages)

                                // setVideoProductDetailURL({
                                //     loading: false,
                                //     videoUrl: val.videoURL
                                // })

                                setModalTour({ isShow: true, title: "edit" })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleToursDelete(val.id)

                                // reload
                                await getToursAll("")
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            })
        })
        setList(tempList)
        searchNameRef.current = title

        setLoading(false)
    }

    const onTourFinish = async (values) => {
        let param = {
            id: values.id ? values.id : ""
        }

        let body = {
            "slugName": "slugName",
            "imageThumbnail": imageThumbnailURL.imageUrl ? imageThumbnailURL.imageUrl : "",
            "title": values.title ? values.title : "",
            "subTitle": values.subTitle ? values.subTitle : "",
            "keyword": values.keyword ? values.keyword : "",
            "startPriceAdult": values.startPriceAdult ? values.startPriceAdult : "",
            "startPriceChild": values.startPriceChild ? values.startPriceChild : "",
            "isActive": values.isActive ? 1 : 0,
            "isHighlight": values.isHighlight ? 1 : 0,
            "highlightSort": values.highlightSort ? values.highlightSort : null,
            "videoTourDetail": values.videoTourDetail ? values.videoTourDetail : "",
        }
        // console.log("body : ", body)

        if (modalTour.title === "add") {
            const result = await insertToursFetch(null, body, accessToken)
            if (result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modalTour.title === "edit") {
            const result = await updateToursFetch(param, body, accessToken)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getToursAll("")

        // set default
        setFormTourDefault()
    }

    // const onPropertyGalleryFinish = async (values) => {

    // }

    const onSearchFinish = async (values) => {
        let title = values?.title ? values.title : ""
        await getToursAll(title)
    }

    const handleToursDelete = async (id) => {
        let param = {
            id
        }
        const result = await deleteToursByIdFetch(param, null, accessToken)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleToursGalleryDelete = async (id) => {
        let param = {
            id
        }
        // console.log("param : ", param)
        const result = await deleteTourImageByIdFetch(param, null, accessToken)
        if (result.isSuccess) {

            let tmpTourGallery = tourGallery?.filter(fill => fill.id !== id)
            // console.log("tmpTourGallery : ", tmpTourGallery)
            setTourGallery(tmpTourGallery)

            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleToursActivityGalleryDelete = async (id) => {
        let param = {
            id
        }
        // console.log("param : ", param)
        const result = await deleteTourActivityImageByIdFetch(param, null, accessToken)
        if (result.isSuccess) {

            let tmpTourActivityGallery = tourActivityGallery?.filter(fill => fill.id !== id)
            // console.log("tmpTourActivityGallery : ", tmpTourActivityGallery)
            setTourActivityGallery(tmpTourActivityGallery)

            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        pageCurrentRef.current = n.current
        getToursAll(searchNameRef.current)
    }

    const setFormTourDefault = () => {
        formTour.setFieldsValue({
            id: undefined, // 
            tourId: undefined, // 
            title: undefined, // 
            subTitle: undefined, // 
            keyword: undefined, // 
            startPriceAdult: undefined, // 
            startPriceChild: undefined, // 
            isActive: undefined, // 
            isHighlight: undefined, // 
            highlightSort: undefined, // 
            videoTourDetail: undefined, // 
        })

        setImageThumbnailURL({
            loading: false,
            imageUrl: null
        })

        setImageTourGalleryURL({
            loading: false,
            imageUrl: null
        })

        setImageTourActivityGalleryURL({
            loading: false,
            imageUrl: null
        })

        // setVideoProductDetailURL({
        //     loading: false,
        //     videoUrl: null
        // })

        setModalTour({
            isShow: false,
            title: null
        })
    }

    // const setFormPropertyGalleryDefault = () => {
    //     // formPropertyGallery.setFieldsValue({
    //     //     tourId: undefined,
    //     // })

    //     setModalPropertyGallery({
    //         isShow: false,
    //         title: null
    //     })
    // }

    const getBaseApi = async () => {
        getToursAll("")
    }

    // console.log("tourGallery : ", tourGallery)

    useEffect(() => {
        // console.log("accessToken : ", accessToken)
        getBaseApi()
    }, [])

    useEffect(() => {

    }, [tourGallery])

    return (
        <Row>
            <Col span={12}>
                <label>จัดการทัวร์</label>
            </Col>

            <Col span={12} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                // label="ชื่ออสังหา"
                                name="title"
                                style={{ width: '100%' }}
                            >
                                <Input placeholder="ค้นหาชื่อทัวร์ที่ให้บริการ" />
                            </Form.Item>
                        </div>

                        {/* <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ช่วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div> */}

                        <div style={{ paddingLeft: 10, marginTop: -24 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => formSearch.submit()}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        formTour.resetFields()

                        setTourGallery([])

                        setTourActivityGallery([])

                        setModalTour({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่มรายการ</Button>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrentRef.current,
                        pageSize: pageSize,
                        total: total
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modalTour.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modalTour.isShow}
                zIndex={999}
                onCancel={() => {

                    // default
                    setFormTourDefault()
                }}
                width={"90%"}
                onOk={() => {
                    formTour.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={formTour}
                    onFinish={onTourFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col xs={24} md={12} xl={12}>
                            <Row gutter={[24, 0]}>
                                <Col span={24}>
                                    <Form.Item name="id" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="tourId" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="title"
                                        label="ชื่อทัวร์"
                                        rules={[{ required: true, message: 'กรุณากรอกชื่อทัวร์' }]}
                                    >
                                        <Input placeholder='ชื่อทัวร์'/>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="subTitle"
                                        label="รายละเอียดทัวร์"
                                        rules={[{ required: true, message: 'กรุณากรอกรายละเอียดทัวร์' }]}
                                    >
                                        <TextArea
                                            autoSize={{ minRows: 3, maxRows: 6 }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="keyword"
                                        label="Keywords"
                                        rules={[{ required: true, message: 'กรุณากรอก Keywords' }]}
                                    >
                                        <Input placeholder='Keywords'/>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="startPriceChild"
                                        label="ราคาเด็ก เริ่มต้น"
                                        rules={[{ 
                                            required: true, 
                                            pattern: new RegExp(/^[0-9]+$/),
                                            message: 'กรุณากรอก ราคาเด็ก เริ่มต้น เป็นตัวเลข' 
                                        }]}
                                    >
                                        <Input placeholder='ราคาเด็ก เริ่มต้น'/>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="startPriceAdult"
                                        label="ราคาผู้ใหญ่ เริ่มต้น"
                                        rules={[{ 
                                            required: true, 
                                            pattern: new RegExp(/^[0-9]+$/),
                                            message: 'กรุณากรอก ราคาผู้ใหญ่ เริ่มต้น เป็นตัวเลข' 
                                        }]}
                                    >
                                        <Input placeholder='ราคาผู้ใหญ่ เริ่มต้น'/>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="videoTourDetail"
                                        label="Link Youtube วิดีโอกิจกรรม"
                                        rules={[{ 
                                            required: true, 
                                            message: 'กรุณากรอก Link Youtube วิดีโอกิจกรรม' 
                                        },
                                        {
                                            type: "url",
                                            message: "กรุณากรอก Link Youtube วิดีโอกิจกรรมในรูปแบบ Url"
                                        }]}
                                    >
                                        <Input placeholder='Link Youtube วิดีโอกิจกรรม'/>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="isActive"
                                        label="เเสดงสินค้าหรือไม่ ?"
                                        rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            <Option key={0} value={true}>เปิด</Option>
                                            <Option key={1} value={false}>ปิด</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="isHighlight"
                                        label="เเสดงไฮไลท์ทัวร์หรือไม่ ?"
                                        rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            <Option key={0} value={true}>เปิด</Option>
                                            <Option key={1} value={false}>ปิด</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="highlightSort"
                                        label="ลำดับการแสดงไฮไลท์ทัวร์"
                                    >
                                        <Input placeholder='ลำดับการแสดงไฮไลท์ทัวร์'/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <Row gutter={[24, 0]}>
                                <Col xs={24} md={12} xl={12}>
                                    <div style={{ display: "grid" }}>
                                        <label style={{ paddingBottom: 6 }}>ภาพปกทัวร์</label>
                                        {imageThumbnailURL?.imageUrl ?
                                            <img
                                                style={{ borderRadius: 8, maxWidth: "100%", border: "1px solid #EEEEEE" }}
                                                src={`${imageThumbnailURL.imageUrl}`}
                                            />
                                            :
                                            <img
                                                style={{ width: "100%", borderRadius: 8, border: "1px solid #C4C4C4" }}
                                                src={`./assets/images/default/df-img.png`}
                                            />
                                        }
                                        <div style={{ paddingTop: 24, paddingBottom: 24 }}>
                                            <Upload
                                                {...optionTourImage}
                                                accept='image/jpeg, image/png, image/jfif'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={false}
                                            >
                                                <Button
                                                    type="default"
                                                    style={{ width: "100%" }}
                                                    icon={imageThumbnailURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                >อัพโหลดรูปภาพ</Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </Col>

                                {modalTour.title !== "add" ?
                                    <Col span={24} style={{ paddingTop: 24 }}>
                                        <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                                            <label>รายการรูปรายละเอียดทัวร์</label>

                                            {/* <Button
                                            type="primary"
                                            style={{ float: 'right' }}
                                            onClick={() => {
                                                isActiveTourGalleryRef.current = true
                                                setModalPropertyGallery({
                                                    isShow: true,
                                                    title: "เพิ่มภาพอสังหาฯ"
                                                })
                                            }}
                                        >เพิ่มรูปผลงาน</Button> */}

                                            <Upload
                                                {...optionTourGalleryImage}
                                                accept='image/jpeg, image/png, image/jfif'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={false}
                                            >
                                                <Button
                                                    type="primary"
                                                    style={{ width: "100%" }}
                                                    icon={imageTourGalleryURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                >เพิ่มรูปรายละเอียดทัวร์</Button>
                                            </Upload>

                                        </div>
                                        <div style={{ paddingTop: 12, paddingBottom: 24 }}>
                                            <div style={{ padding: 12, border: "2px solid #EEEEEE", borderRadius: 16 }}>
                                                <Row gutter={[12, 24]}>
                                                    {!loadingTourGallery ?
                                                        <>
                                                            {tourGallery?.map((val, index) => {
                                                                return (
                                                                    <Col xs={24} md={12} xl={6}>
                                                                        <img
                                                                            // src={`${serverUrl}/${val.image}`}
                                                                            src={`${val.image}`}
                                                                            style={{ objectFit: "cover", width: "100%", height: 200, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
                                                                        />
                                                                        <Button
                                                                            type="primary"
                                                                            style={{ width: '100%' }}
                                                                            onClick={async () => {
                                                                                await handleToursGalleryDelete(val.id)
                                                                            }}
                                                                        >ลบรูปภาพ</Button>
                                                                    </Col>
                                                                )
                                                            })}
                                                        </>
                                                        : []
                                                    }

                                                    {tourGallery?.length === 0 ?
                                                        <Col span={24}>
                                                            <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                                                <label>ไม่มีรูปภาพ</label>
                                                            </div>
                                                        </Col>
                                                        : []
                                                    }
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                    : []
                                }

                                {modalTour.title !== "add" ?
                                    <Col span={24} style={{ paddingTop: 24 }}>
                                        <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                                            <label>รายการรูปกิจกรรมทัวร์</label>

                                            {/* <Button
                                            type="primary"
                                            style={{ float: 'right' }}
                                            onClick={() => {
                                                isActiveTourActivityGalleryRef.current = true
                                                setModalPropertyGallery({
                                                    isShow: true,
                                                    title: "เพิ่มภาพอสังหาฯ"
                                                })
                                            }}
                                        >เพิ่มรูปผลงาน</Button> */}

                                            <Upload
                                                {...optionTourActivityGalleryImage}
                                                accept='image/jpeg, image/png, image/jfif'
                                                style={{ width: "100%" }}
                                                maxCount={1}
                                                showUploadList={false}
                                            >
                                                <Button
                                                    type="primary"
                                                    style={{ width: "100%" }}
                                                    icon={imageTourActivityGalleryURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                >เพิ่มรูปกิจกรรมทัวร์</Button>
                                            </Upload>

                                        </div>
                                        <div style={{ paddingTop: 12, paddingBottom: 24 }}>
                                            <div style={{ padding: 12, border: "2px solid #EEEEEE", borderRadius: 16 }}>
                                                <Row gutter={[12, 24]}>
                                                    {!loadingTourActivityGallery ?
                                                        <>
                                                            {tourActivityGallery?.map((val, index) => {
                                                                return (
                                                                    <Col xs={24} md={12} xl={6}>
                                                                        <img
                                                                            // src={`${serverUrl}/${val.image}`}
                                                                            src={`${val.image}`}
                                                                            style={{ objectFit: "cover", width: "100%", height: 200, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
                                                                        />
                                                                        <Button
                                                                            type="primary"
                                                                            style={{ width: '100%' }}
                                                                            onClick={async () => {
                                                                                await handleToursActivityGalleryDelete(val.id)
                                                                            }}
                                                                        >ลบรูปภาพ</Button>
                                                                    </Col>
                                                                )
                                                            })}
                                                        </>
                                                        : []
                                                    }

                                                    {tourActivityGallery?.length === 0 ?
                                                        <Col span={24}>
                                                            <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                                                <label>ไม่มีรูปภาพ</label>
                                                            </div>
                                                        </Col>
                                                        : []
                                                    }
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                    : []
                                }
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Row>
    )
}
