import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getTourDetailPageFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.get(server.GET_TOUR_DETAIL_PAGE_URL + `/${param.id}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getActivityImagesPageFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.get(server.GET_ACTIVITY_IMAGES_PAGE_URL + `/${param.id}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getTourDetailPageFetch,
    getActivityImagesPageFetch,

    // insert

    // update

    // delete

}