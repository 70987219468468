/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Button
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { scroller } from "react-scroll"
import Carouse from '../../../../common/components/carouse'
import { getHomePageSlideBannersFetch } from '../../../../common/components/header/API'

export default function Main() {

    const { width } = useDimensions()

    const [banner, setBanner] = useState([])

    const [loading, setLoading] = useState(false)

    const getPropertiesBySearch = async (name) => {
        setLoading(true)

        let param = {

        }

		let result = await getHomePageSlideBannersFetch(null, null, null)
		// console.log("getHomePageSlideBannersFetch : ", result)

        setBanner(result)

        setLoading(false)
    }

    const getBaseApi = async () => {
        await getPropertiesBySearch(null)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <div
            style={{ paddingTop: 70 }}
        >
            <Carouse data={banner} />
        </div>
    )
}